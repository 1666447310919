import React from "react";
import { graphql } from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Moment from "react-moment";
import Layout from "../components/layout";
import Markdown from "react-markdown";

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }, status: { eq: "published" }) {
      strapiId
      title
      description
      content
      publishedAt
      image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              height: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      author {
        name
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 30
                height: 30
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;

const Article = ({ data }) => {
  const article = data.strapiArticle;
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  };

  return (
    <Layout seo={seo}>
      <div>
        <div 
          class="uk-section uk-height-high uk-flex uk-flex-center uk-flex-middle uk-cover-container uk-light uk-padding"
          >
            {article.image && (
          <GatsbyImage image={getImage(article.image.localFile)}  
          alt={article.image.alternativeText}
          layout="constrained"/>
              // <Img
              //   fluid={article.image.localFile.childImageSharp.fluid}
              //   imgStyle={{ position: "static" }}
              //   alt={article.title}
              // uk-cover/>
            )}
        </div>
        <div className="uk-section">

          <div className="uk-container uk-container-small">
            <Markdown source={article.content} escapeHtml={false} />

            <hr className="uk-divider-small" />

            <div className="uk-grid-small uk-flex-left" data-uk-grid="true">
              <div>
                {article.author.picture && (
                   <GatsbyImage image={getImage(article.author.picture.localFile)} 
                    imgStyle={{ borderRadius: "50%" }} 
                    alt={article.author.name}/>
                )}
              </div>
              <div className="uk-width-expand">
                <p className="uk-margin-remove-bottom">
                  By {article.author.name}
                </p>
                <p className="uk-text-meta uk-margin-remove-top">
                  <Moment format="MMM Do YYYY">{article.published_at}</Moment>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article;